import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

// import axios from "axios";
const ConsultForm = ({ setConsult }) => {
  
  const [consultData, setConsultData] = useState({
    user_name: "",
    user_mobile: "",
    user_email: "",
    user_service: "",
  });

  const InputHandler = (e) => {
    setConsultData({
      ...consultData,
      [e.target.name]: e.target.value,
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (
      consultData.user_name === "" ||
      consultData.user_email === "" ||
      consultData.user_mobile === "" ||
      consultData.user_service === ""
    ) {
      alert("Please fill all the fields");
      return false;
    }

    console.log(consultData);
    // axios
    //   .post("https://testingserver-sgi9.onrender.com/api", consultData)
    //   .then(() => {
    //     console.log(consultData);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    setConsultData({
      user_name: "",
      user_mobile: "",
      user_email: "",
      user_service: "",
    });
    setConsult(false);
    alert("message send successfully");
  };
  return (
    <>
      <div className="consult-form">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header D ">
              <h2
                className="modal-title fs-5 text-white"
                id="exampleModalLabel"
              >
                Talk to Expert
              </h2>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-2">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Name:*
                  </label>
                  <div>
                    <input
                      type="text"
                      id="recipient-name"
                      className=" w-100"
                      name="user_name"
                      placeholder="Enter Name"
                      value={consultData.user_name}
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="mobile" className="col-form-lable">
                    Mobile:*
                  </label>
                  <div>
                    <input
                      type="text"
                      id="mobile"
                      className="w-100"
                      maxLength={10}
                      pattern="[0-9]*"
                      name="user_mobile"
                      placeholder="Enter Number"
                      value={consultData.user_mobile}
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="email" className="col-form-lable">
                    Email:*
                  </label>
                  <div>
                    <input
                      type="email"
                      id="email"
                      className="w-100"
                      name="user_email"
                      value={consultData.user_email}
                      placeholder="Enter Email"
                      onChange={InputHandler}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="mb-2">
                  <label htmlFor="message-text" className="col-form-label">
                    Message:*
                  </label>
                  <div>
                    <select
                      className="w-100 p-2"
                      value={consultData.user_service}
                      name="user_service"
                      onChange={InputHandler}
                      required
                    >
                      <option>Select Services</option>
                      <option>Social Media Marketing</option>
                      <option>Digital Marketing</option>
                      <option>SEO</option>
                      <option>Creative Work</option>
                    </select>
                  </div>
                </div>
                
                <div className=" my-1  rounded text-center ">
                  <ReCAPTCHA
                    className="recaptcha"
                    sitekey="6LcbSFwqAAAAAHspleA0GuZx09JLFvXGaplyVxdw"
                  />
                </div>
                <div className="modal-footer ">
                  <button
                    type="button"
                    onClick={() => setConsult(false)}
                    className="btn btn-D"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-T"
                    onClick={submitHandler}
                  >
                    Send message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsultForm;
