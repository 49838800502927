import React from "react";
import { WebRoutes } from "../../routes";
import { Link, NavLink } from "react-router-dom";
import Slider from "./Slider";
import Tabbar from "./Tabbar";
import AllData from "./AllData.jsx";
import { Helmet } from "react-helmet-async";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>DMT Media & Marketing |Digital Marketing Agency</title>
        <meta name="description" content="Boost your business with DMT Media & Marketing, a results-driven digital marketing agency offering expert SEO, PPC, social media management, and web design services" />
        <meta name="keywords" content="Digital marketing, SEO, SMM, DMT Media & Marketing, PPC, digital advertising, DMT Media" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <section className="banner bg-tertiary position-relative overflow-hidden">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 mb-5 mb-lg-0" data-aos="fade-right">
              <div className="block text-center text-lg-start pe-0 pe-xl-5">
                <h1 className="text-capitalize mb-4">
                  We are Media & Marketing Advisors
                </h1>
                <p className="mb-4">
                  We Specialize in developing the best Marketing Strategy for
                  your Digital Business.
                </p>
                <Link
                  type="button"
                  className="btn btn-T"
                  to={WebRoutes.CONTACT_US}
                >
                  Let's Connect
                  <span
                    style={{ fontSize: "14px" }}
                    className="ms-2 fas fa-arrow-right"
                  ></span>
                </Link>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-left">
              <div className="text-center">
                <img
                  loading="lazy"
                  decoding="async"
                  src={require("../../static/img/banner-img.svg").default}
                  alt="banner"
                  className="w-100 py-4"
                  title="hero-image"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="icon-section mt-4">
                <ul className="list-unstyled list-inline mb-0 ">
                  <li className="list-inline-item me-4">
                    <NavLink
                      to="https://www.linkedin.com/company/dmt-media-marketing/"
                      target="_blank"
                      className="text-black fw-bold d-flex  align-items-center"
                    >
                      <img
                        src={require("../../static/img/linkedin-removebg-preview.png")}
                        width="20px"
                        height="20px"
                        alt="LINKEDIN"
                        loading="lazy"
                        title="LINKEDIN"
                      />
                      &nbsp;LINKEDIN
                    </NavLink>
                  </li>
                  <li className="list-inline-item me-4">
                    <NavLink
                      to="https://www.facebook.com/dmt.mediamarketing/"
                      target="_blank"
                      className="text-black fw-bold d-flex  align-items-center"
                    >
                      <img
                        src={require("../../static/img/download-removebg-preview (1).png")}
                        width="20px"
                        height="20px"
                        alt="FACEBOOK"
                        loading="lazy"
                        title="FACEBOOK"
                      />
                      &nbsp;FACEBOOK
                    </NavLink>
                  </li>
                  <li className="list-inline-item me-4">
                    <NavLink
                      to="https://www.instagram.com/dmt_mediamarketing/"
                      target="_blank"
                      className="text-black fw-bold d-flex  align-items-center"
                    >
                      <img
                        src={require("../../static/img/Instagram_logo_2022.svg-removebg-preview.png")}
                        width="20px"
                        height="20px"
                        alt="INSTAGRAM"
                        loading="lazy"
                        title="INSTAGRAM"
                      />
                      &nbsp;INSTAGRAM
                    </NavLink>
                  </li>
                  <li className="list-inline-item me-4">
                    <NavLink
                      to="https://x.com/DMTMediaIndia"
                      target="_blank"
                      className="text-black fw-bold d-flex  align-items-center"
                    >
                      <img
                        src={require("../../static/img/download__2_-removebg-preview.png")}
                        width="20px"
                        height="20px"
                        loading="lazy"
                        alt="TWITTER"
                        title="TWITTER"
                      />
                      &nbsp;TWITTER
                    </NavLink>
                  </li>
                  <li className="list-inline-item me-4">
                    <NavLink
                      to="https://www.youtube.com/channel/UCLeYQv4kvK9vvX_54NUOkfw"
                      target="_blank"
                      className="text-black fw-bold d-flex  align-items-center"
                    >
                      <img
                        src={require("../../static/img/download__1_-removebg-preview.png")}
                        width="20px"
                        height="20px"
                        alt="YOUTUBE"
                        loading="lazy"
                        title="YOUTUBE"
                      />
                      &nbsp;YOUTUBE
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="has-shapes">
          <svg
            className="shape shape-left text-light"
            viewBox="0 0 192 752"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-30.883 0C-41.3436 36.4248 -22.7145 75.8085 4.29154 102.398C31.2976 128.987 65.8677 146.199 97.6457 166.87C129.424 187.542 160.139 213.902 172.162 249.847C193.542 313.799 149.886 378.897 129.069 443.036C97.5623 540.079 122.109 653.229 191 728.495"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M-55.5959 7.52271C-66.0565 43.9475 -47.4274 83.3312 -20.4214 109.92C6.58466 136.51 41.1549 153.722 72.9328 174.393C104.711 195.064 135.426 221.425 147.449 257.37C168.829 321.322 125.174 386.42 104.356 450.559C72.8494 547.601 97.3965 660.752 166.287 736.018"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M-80.3302 15.0449C-90.7909 51.4697 -72.1617 90.8535 -45.1557 117.443C-18.1497 144.032 16.4205 161.244 48.1984 181.915C79.9763 202.587 110.691 228.947 122.715 264.892C144.095 328.844 100.439 393.942 79.622 458.081C48.115 555.123 72.6622 668.274 141.552 743.54"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M-105.045 22.5676C-115.506 58.9924 -96.8766 98.3762 -69.8706 124.965C-42.8646 151.555 -8.29436 168.767 23.4835 189.438C55.2615 210.109 85.9766 236.469 98.0001 272.415C119.38 336.367 75.7243 401.464 54.9072 465.604C23.4002 562.646 47.9473 675.796 116.838 751.063"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
          </svg>
          <svg
            className="shape shape-right text-light"
            viewBox="0 0 731 746"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.1794 745.14C1.80036 707.275 -5.75764 666.015 8.73984 629.537C27.748 581.745 80.4729 554.968 131.538 548.843C182.604 542.703 234.032 552.841 285.323 556.748C336.615 560.64 391.543 557.276 433.828 527.964C492.452 487.323 511.701 408.123 564.607 360.255C608.718 320.353 675.307 307.183 731.29 327.323"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M51.0253 745.14C41.2045 709.326 34.0538 670.284 47.7668 635.783C65.7491 590.571 115.623 565.242 163.928 559.449C212.248 553.641 260.884 563.235 309.4 566.931C357.916 570.627 409.887 567.429 449.879 539.701C505.35 501.247 523.543 426.331 573.598 381.059C615.326 343.314 678.324 330.853 731.275 349.906"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M89.8715 745.14C80.6239 711.363 73.8654 674.568 86.8091 642.028C103.766 599.396 150.788 575.515 196.347 570.054C241.906 564.578 287.767 573.629 333.523 577.099C379.278 580.584 428.277 577.567 465.976 551.423C518.279 515.172 535.431 444.525 582.62 401.832C621.964 366.229 681.356 354.493 731.291 372.46"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M128.718 745.14C120.029 713.414 113.678 678.838 125.837 648.274C141.768 608.221 185.939 585.788 228.737 580.659C271.536 575.515 314.621 584.008 357.6 587.282C400.58 590.556 446.607 587.719 482.028 563.16C531.163 529.111 547.275 462.733 591.612 422.635C628.572 389.19 684.375 378.162 731.276 395.043"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M167.564 745.14C159.432 715.451 153.504 683.107 164.863 654.519C179.753 617.046 221.088 596.062 261.126 591.265C301.164 586.452 341.473 594.402 381.677 597.465C421.88 600.527 464.95 597.872 498.094 574.896C544.061 543.035 559.146 480.942 600.617 443.423C635.194 412.135 687.406 401.817 731.276 417.612"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M817.266 289.466C813.108 259.989 787.151 237.697 759.261 227.271C731.372 216.846 701.077 215.553 671.666 210.904C642.254 206.24 611.795 197.156 591.664 175.224C555.853 136.189 566.345 75.5336 560.763 22.8649C552.302 -56.8256 498.487 -130.133 425 -162.081"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M832.584 276.159C828.427 246.683 802.469 224.391 774.58 213.965C746.69 203.539 716.395 202.246 686.984 197.598C657.573 192.934 627.114 183.85 606.982 161.918C571.172 122.883 581.663 62.2275 576.082 9.55873C567.62 -70.1318 513.806 -143.439 440.318 -175.387"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M847.904 262.853C843.747 233.376 817.789 211.084 789.9 200.659C762.011 190.233 731.716 188.94 702.304 184.292C672.893 179.627 642.434 170.544 622.303 148.612C586.492 109.577 596.983 48.9211 591.402 -3.74766C582.94 -83.4382 529.126 -156.746 455.638 -188.694"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M863.24 249.547C859.083 220.07 833.125 197.778 805.236 187.353C777.347 176.927 747.051 175.634 717.64 170.986C688.229 166.321 657.77 157.237 637.639 135.306C601.828 96.2707 612.319 35.6149 606.738 -17.0538C598.276 -96.7443 544.462 -170.052 470.974 -202"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 " data-aos="fade-right">
              <div className="section-title pt-4">
                <h2 style={{ color: "rgb(16,116,190)" }}>Our Services</h2>
                <p className="para">
                  Our passion is in delivering business outcomes and having a
                  positive business impact. If you want a full suite of
                  marketing solutions, we get under your skin to understand your
                  brand’s goals and challenges. Our services deliver outcomes
                  and better results for our clients by providing best-in-class
                  expertise. We maximize your ROI and marketing budgets, while
                  taking the risk out of your investment.
                </p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 ">
              <div
                className="row justify-content-evenly gy-2"
                data-aos="fade-up"
              >
                <div className="col-lg-5 col-md-5 blurBg service-item ">
                  <Link className="text-black" to={WebRoutes.HOME}>
                    <div className="block text-center ">
                      <span className="colored-box text-center h3 mb-4">
                        <img src={require("../../static/img/Icon/management.png")} alt="management-img" 
                        loading="lazy" title="management-img" />
                      </span>
                      <h3 className="mb-2 service-title">
                        Social Media Management
                      </h3>
                      <p className="mb-0 service-description">
                        Streamline your social media presence with our professional management services. We handle content creation, posting schedules, audience engagement, and performance tracking to ensure your brand stays active and relevant.

                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-5 col-md-5 blurBg service-item ">
                  <Link className="text-black" to={WebRoutes.HOME}>
                    <div className="block text-center">
                      <span className="colored-box text-center h3 mb-4">
                        <img src={require("../../static/img/Icon/digital.png")} alt="digital" 
                        loading="lazy"
                        title="digital-img" />
                      </span>
                      <h3 className="mb-3 service-title">Digital Marketing Service</h3>
                      <p className="mb-0 service-description">
                        Transform your online presence with our comprehensive digital marketing services. From SEO and content marketing to PPC and social media strategies, we help you reach your target audience and achieve measurable results.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-5 col-md-5 blurBg service-item  ">
                  <Link className="text-black" to={WebRoutes.HOME}>
                    <div className="block text-center">
                      <span className="colored-box text-center h3 mb-4">
                        <img src={require("../../static/img/Icon/seo.png")} alt="seo" title="seo" loading="lazy" />
                      </span>
                      <h3 className="mb-3 service-title">
                        Search Engine Optimization
                      </h3>
                      <p className="mb-0 service-description">
                        Boost your website's visibility and drive organic traffic with our expert SEO services. We optimize your site for search engines, ensuring higher rankings, better user experience, and increased conversions.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-5 col-md-5 blurBg service-item ">
                  <Link className="text-black" to={WebRoutes.HOME}>
                    <div className="block text-center">
                      <span className="colored-box text-center h3 mb-4">
                        <img src={require("../../static/img/Icon/ui.png")} alt="ui" title="ui" loading="lazy"/>
                      </span>
                      <h3 className="mb-3 service-title">UI/UX Design</h3>
                      <p className="mb-0 service-description">
                        Enhance user satisfaction with our intuitive UI/UX design services. We create visually appealing, user-friendly interfaces that provide seamless and engaging experiences for your customers.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section section bg-tertiary position-relative overflow-hidden">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-12">
              <div className="section-title " data-aos="fade-right">
                <h3 style={{ color: "rgb(16,116,190)" }}>
                  Accelerate Your Business with Our High-Performance E-commerce
                  & Digital Marketing Services that Connects the Brand to the
                  Customer
                </h3>
                <p>Who we are and what we do</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-2">
            {AllData.map((item, index) => {
              return (
                <div
                  className="icon-box-item  col-lg-3 col-md-4 col-12 mx-auto mx-sm-2"
                  data-aos="fade-up"
                  key={index}
                >
                  <div className="block_here">
                    <div className="icon" style={{ color: "white" }}>
                      {item.icon}{" "}
                    </div>
                    <h3 className="mb-3 p-1">{item.tittle}</h3>
                    <p className="mb-0 p-1">{item.subtitle}</p>
                  </div>
                </div>
              );
            })}{" "}
          </div>
        </div>
      </section>

      <section className="about-section section bg-tertiary position-relative overflow-hidden d-none">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5" data-aos="fade-right">
              <div className="section-title">
                <p className="text-primary text-uppercase fw-bold mb-3">
                  About Us
                </p>
                <h2>We value our Business Relations.</h2>
                <p className="lead mb-0 mt-4">
                  We are committed to advancing the communications industry
                  through cutting-edge analytics, uniquely creative, impactful
                  creation, and strategically driven company. We work towards
                  the satisfaction of our clients and deliver the highest
                  quality standards. We take pride in our strong portfolio of
                  highly gratified clientele, thus continually driving
                  innovation across industry verticals. Customer satisfaction is
                  our sole motto, our mission is to have a long-lasting
                  relationship with our clients. We make sure to learn something
                  new to rectify previous errors to deliver quality work to our
                  customers.
                </p>

                <Link className="btn btn-primary mt-4" to={WebRoutes.HOME}>
                  Know About Us
                </Link>
              </div>
            </div>
            <div
              className="col-lg-7 text-center text-lg-end"
              data-aos="fade-left"
            >
              <img
                loading="lazy"
                decoding="async"
                src={require("../../static/img/aboutUs.svg").default}
                alt="About Ourselves"
                title="About Ourselves"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="has-shapes">
          <svg
            className="shape shape-left text-light"
            width="381"
            height="443"
            viewBox="0 0 381 443"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M334.266 499.007C330.108 469.108 304.151 446.496 276.261 435.921C248.372 425.346 218.077 424.035 188.666 419.32C159.254 414.589 128.795 405.375 108.664 383.129C72.8533 343.535 83.3445 282.01 77.7634 228.587C69.3017 147.754 15.4873 73.3967 -58.0001 40.9907"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M349.584 485.51C345.427 455.611 319.469 433 291.58 422.425C263.69 411.85 233.395 410.538 203.984 405.823C174.573 401.092 144.114 391.878 123.982 369.632C88.1716 330.038 98.6628 268.513 93.0817 215.09C84.62 134.258 30.8056 59.8999 -42.6819 27.494"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M364.904 472.013C360.747 442.114 334.789 419.503 306.9 408.928C279.011 398.352 248.716 397.041 219.304 392.326C189.893 387.595 159.434 378.381 139.303 356.135C103.492 316.541 113.983 255.016 108.402 201.593C99.9403 120.76 46.1259 46.4028 -27.3616 13.9969"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M380.24 458.516C376.083 428.617 350.125 406.006 322.236 395.431C294.347 384.856 264.051 383.544 234.64 378.829C205.229 374.098 174.77 364.884 154.639 342.638C118.828 303.044 129.319 241.519 123.738 188.096C115.276 107.264 61.4619 32.906 -12.0255 0.500103"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
          </svg>
          <svg
            className="shape shape-right text-light"
            width="406"
            height="433"
            viewBox="0 0 406 433"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M101.974 -86.77C128.962 -74.8992 143.467 -43.2447 146.175 -12.7857C148.883 17.6734 142.273 48.1263 139.087 78.5816C135.916 109.041 136.681 141.702 152.351 167.47C180.247 213.314 240.712 218.81 289.413 238.184C363.095 267.516 418.962 340.253 430.36 421.687"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M118.607 -98.5031C145.596 -86.6323 160.101 -54.9778 162.809 -24.5188C165.517 5.94031 158.907 36.3933 155.72 66.8486C152.549 97.3082 153.314 129.969 168.985 155.737C196.881 201.581 257.346 207.077 306.047 226.451C379.729 255.783 435.596 328.52 446.994 409.954"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M135.241 -110.238C162.23 -98.3675 176.735 -66.7131 179.443 -36.254C182.151 -5.79492 175.541 24.6581 172.354 55.1134C169.183 85.573 169.948 118.234 185.619 144.002C213.515 189.846 273.98 195.342 322.681 214.716C396.363 244.048 452.23 316.785 463.627 398.219"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
            <path
              d="M151.879 -121.989C178.867 -110.118 193.373 -78.4638 196.081 -48.0047C198.789 -17.5457 192.179 12.9074 188.992 43.3627C185.821 73.8223 186.586 106.483 202.256 132.251C230.153 178.095 290.618 183.591 339.318 202.965C413.001 232.297 468.867 305.034 480.265 386.468"
              stroke="currentColor"
              strokeMiterlimit="10"
            />
          </svg>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5" data-aos="fade-right">
              <div className="section-title">
                <p className="text-primary text-uppercase fw-bold mb-3">
                  What Makes Us Different From Others
                </p>
                <h2 className="fs-1">
                  <span className="color-1">
                    Why <span className="d">D</span>
                    <span className="m">M</span>
                    <span className="t">T</span>{" "}
                  </span>
                  <span className="color-1">Media &</span>
                  <span className="color-1"> Marketing?</span>
                </h2>
                <div className="content mb-0 mt-4 text-justify">
                  <p>
                    We are a perfectly result-oriented company that requires a
                    balance of ingredients that combines different elements to
                    create a deliciously effective marketing result. Our team is
                    skilled in developing unique and creative robust marketing
                    solutions that capture attention and engage audiences At DMT
                    MEDIA & MARKETING PRIVATE LIMITED, we work with our clients
                    to develop a customized marketing strategy that balances
                    different approaches to achieve your marketing objectives.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-left">
              <div className="difference-of-us-item p-3 rounded mr-0 me-lg-4">
                <div
                  className="d-block d-sm-flex align-items-center m-2"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div className="icon me-4 mb-4 mb-sm-0">
                    <i
                      className="fas fa-shield-alt mt-4"
                      style={{ fontSize: "36px" }}
                    ></i>
                  </div>
                  <div className="block">
                    <h3 className="mb-3">Growth Marketing Framework</h3>
                    <p className="mb-0">
                      Creating a Digital Ecosystem to ensure long-term growth
                      with cost-effective marketing solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="difference-of-us-item p-3 rounded mr-0 me-lg-4"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="d-block d-sm-flex align-items-center m-2">
                  <div className="icon me-4 mb-4 mb-sm-0">
                    <i
                      className="fas fa-blender-phone mt-4"
                      style={{ fontSize: "36px" }}
                    ></i>
                  </div>
                  <div className="block">
                    <h3 className="mb-3">Timely Delivery</h3>
                    <p className="mb-0">
                      Quicker in response & outputs delivered within deadlines
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="difference-of-us-item p-3 rounded mr-0 me-lg-4"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="d-block d-sm-flex align-items-center m-2">
                  <div className="icon me-4 mb-4 mb-sm-0">
                    <i
                      className="fas fa-money-bill-alt mt-4"
                      style={{ fontSize: "36px" }}
                    ></i>
                  </div>
                  <div className="block">
                    <h3 className="mb-3">Quality Assurance</h3>
                    <p className="mb-0">
                      We make sure to meet the Industry-level quality standards
                      for every requirement to deliver on our promise of trust &
                      reliability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Slider />
      <Tabbar />
    </>
  );
};

export default Home;
