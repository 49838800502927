import React from 'react'
import { Link } from "react-router-dom";
import { WebRoutes } from "../../routes";
import { Helmet } from 'react-helmet-async';
const Privacy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | DMT Media & Marketing</title>
                <meta name="description" content="DMT Media offers digital services to help businesses grow online. From SEO and SMM to Content Creation and PPC." />
                <meta name="keywords" content="Digital marketing, SEO, SMM, DMT Media & Marketing, PPC, digital advertising, DMT Media" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <section className="page-header privacy-page">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-8 mx-auto text-center">

                            <ul className="breadcrumbs"
                                style={
                                    { transform: "translateY(100px)" }
                                }>
                                <li className="list-inline-item">
                                    <Link to={
                                        WebRoutes.HOME
                                    }
                                        style={
                                            { color: "#000" }
                                        }>Home</Link>
                                </li>
                                <li className="list-inline-item">
                                    <Link to={
                                        WebRoutes.ABOUT_US
                                    }>Privacy Policy</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="has-shapes">
                    <svg className="shape shape-left text-light" viewBox="0 0 192 752" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-30.883 0C-41.3436 36.4248 -22.7145 75.8085 4.29154 102.398C31.2976 128.987 65.8677 146.199 97.6457 166.87C129.424 187.542 160.139 213.902 172.162 249.847C193.542 313.799 149.886 378.897 129.069 443.036C97.5623 540.079 122.109 653.229 191 728.495" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M-55.5959 7.52271C-66.0565 43.9475 -47.4274 83.3312 -20.4214 109.92C6.58466 136.51 41.1549 153.722 72.9328 174.393C104.711 195.064 135.426 221.425 147.449 257.37C168.829 321.322 125.174 386.42 104.356 450.559C72.8494 547.601 97.3965 660.752 166.287 736.018" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M-80.3302 15.0449C-90.7909 51.4697 -72.1617 90.8535 -45.1557 117.443C-18.1497 144.032 16.4205 161.244 48.1984 181.915C79.9763 202.587 110.691 228.947 122.715 264.892C144.095 328.844 100.439 393.942 79.622 458.081C48.115 555.123 72.6622 668.274 141.552 743.54" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M-105.045 22.5676C-115.506 58.9924 -96.8766 98.3762 -69.8706 124.965C-42.8646 151.555 -8.29436 168.767 23.4835 189.438C55.2615 210.109 85.9766 236.469 98.0001 272.415C119.38 336.367 75.7243 401.464 54.9072 465.604C23.4002 562.646 47.9473 675.796 116.838 751.063" stroke="currentColor" strokeMiterlimit="10" />
                    </svg>
                    <svg className="shape shape-right text-light" viewBox="0 0 731 746" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.1794 745.14C1.80036 707.275 -5.75764 666.015 8.73984 629.537C27.748 581.745 80.4729 554.968 131.538 548.843C182.604 542.703 234.032 552.841 285.323 556.748C336.615 560.64 391.543 557.276 433.828 527.964C492.452 487.323 511.701 408.123 564.607 360.255C608.718 320.353 675.307 307.183 731.29 327.323" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M51.0253 745.14C41.2045 709.326 34.0538 670.284 47.7668 635.783C65.7491 590.571 115.623 565.242 163.928 559.449C212.248 553.641 260.884 563.235 309.4 566.931C357.916 570.627 409.887 567.429 449.879 539.701C505.35 501.247 523.543 426.331 573.598 381.059C615.326 343.314 678.324 330.853 731.275 349.906" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M89.8715 745.14C80.6239 711.363 73.8654 674.568 86.8091 642.028C103.766 599.396 150.788 575.515 196.347 570.054C241.906 564.578 287.767 573.629 333.523 577.099C379.278 580.584 428.277 577.567 465.976 551.423C518.279 515.172 535.431 444.525 582.62 401.832C621.964 366.229 681.356 354.493 731.291 372.46" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M128.718 745.14C120.029 713.414 113.678 678.838 125.837 648.274C141.768 608.221 185.939 585.788 228.737 580.659C271.536 575.515 314.621 584.008 357.6 587.282C400.58 590.556 446.607 587.719 482.028 563.16C531.163 529.111 547.275 462.733 591.612 422.635C628.572 389.19 684.375 378.162 731.276 395.043" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M167.564 745.14C159.432 715.451 153.504 683.107 164.863 654.519C179.753 617.046 221.088 596.062 261.126 591.265C301.164 586.452 341.473 594.402 381.677 597.465C421.88 600.527 464.95 597.872 498.094 574.896C544.061 543.035 559.146 480.942 600.617 443.423C635.194 412.135 687.406 401.817 731.276 417.612" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M817.266 289.466C813.108 259.989 787.151 237.697 759.261 227.271C731.372 216.846 701.077 215.553 671.666 210.904C642.254 206.24 611.795 197.156 591.664 175.224C555.853 136.189 566.345 75.5336 560.763 22.8649C552.302 -56.8256 498.487 -130.133 425 -162.081" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M832.584 276.159C828.427 246.683 802.469 224.391 774.58 213.965C746.69 203.539 716.395 202.246 686.984 197.598C657.573 192.934 627.114 183.85 606.982 161.918C571.172 122.883 581.663 62.2275 576.082 9.55873C567.62 -70.1318 513.806 -143.439 440.318 -175.387" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M847.904 262.853C843.747 233.376 817.789 211.084 789.9 200.659C762.011 190.233 731.716 188.94 702.304 184.292C672.893 179.627 642.434 170.544 622.303 148.612C586.492 109.577 596.983 48.9211 591.402 -3.74766C582.94 -83.4382 529.126 -156.746 455.638 -188.694" stroke="currentColor" strokeMiterlimit="10" />
                        <path d="M863.24 249.547C859.083 220.07 833.125 197.778 805.236 187.353C777.347 176.927 747.051 175.634 717.64 170.986C688.229 166.321 657.77 157.237 637.639 135.306C601.828 96.2707 612.319 35.6149 606.738 -17.0538C598.276 -96.7443 544.462 -170.052 470.974 -202" stroke="currentColor" strokeMiterlimit="10" />
                    </svg>
                </div>
            </section>
            <section>
                <div className="container">

                    <div className='row pt-4'>
                        <div className='col-12 privacy'>
                            <h1 className='text-center'>Privacy Policy</h1>
                            <p>DMT Media & Marketing Pvt Ltd(<span>“DMT”, “we”, “us”, “our”</span>), respects every individual’s right to privacy and it is our endeavour to protect their personal and Sensitive personal data or information. This Privacy Policy (<span>“Policy”</span>) applies to all visitors (i.e. those who do not have an Account with DMT) and users (i.e. those who have an Account with DMT) (collectively referred to as<span>“User”</span>) who share their personal and Sensitive personal data with us, while visiting, accessing, browsing and using our website and mobile application (collectively referred to as <span>“Website”</span>). The purpose of this Policy is to inform Users regarding collection, use, storage, transfer and disclosure of their data. Before submitting any information to us, please carefully read this Policy to understand how we shall treat your information. </p>
                            <p>The User acknowledges that this Policy is a part of DMT’s <span>Terms & Conditions</span> and all terms defined in the Terms of Use have the same meaning here in this Policy. Access, browsing and continuous use of the Website constitutes the User’s unconditional consent to this Policy read together with the Terms of Use. If the User does not agree with the terms of this Policy, please do not visit and use our Website. This Policy can be easily accessed at various places on our Website, including but not limited to when Users create an account with us on our Website.</p>

                            <h4>Definitions</h4>

                            <p><span>Personal Information:</span>
                                Any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with a body corporate, is capable of identifying such person. Such information includes name, address, mobile number, etc.
                            </p>
                            <p><span>Sensitive personal data or information relating to:</span>
                                passwords; financial information such as bank accounts or credit card or debit card or other payment instrument details; physical, physiological and mental health condition; sexual orientation; medical records and history; biometric information; any of the information received under above clauses by body corporate for processing or storage under lawful contract or otherwise. Information which is freely available in the public domain or furnished under the Right to Information Act, 2005 or any other law shall not be regarded as sensitive personal data or information.
                            </p>
                            <p><span>Payment Data:</span>
                                It is defined as end-to-end transaction details and information pertaining to payment or settlement transaction that is gathered / transmitted / processed as part of a payment message / instruction. Payment Data includes - Customer data (Name, Mobile Number, email, Aadhaar Number, PAN number, etc. as applicable); Payment sensitive data (customer and beneficiary account details); Payment Credentials (OTP, PIN, Passwords, etc.); and, Transaction data (originating & destination system information, transaction reference, timestamp, amount, etc.).
                            </p>

                            <p><span>Consent for collection of Personal Information</span>
                                <br />
                                Users provide their consent to the use, disclosure, storage, possession, receiving, dealing or handling of their Personal Information by accessing, browsing, or availing services on the Website. Users provide their specific consent to the use, disclosure, storage, possession, receiving, dealing or handling of their Sensitive personal data or information for lawful purposes enumerated in this policy through checking the box affirming such consent appearing at the time of creation of their Account on the Website.
                            </p>
                            <p>DMT presumes adequate and lawful parental consent in case the Personal Information or Sensitive personal data or information is shared by a User under the age of 18 years. </p>
                            <p><span>Types of Information we collect</span>
                                <br />
                                The Users who access and use our Website are required to submit certain Personal Information and Sensitive personal data or information for creating an Account, entering into transactions with us and obtaining customer support services. Personal Information and Sensitive personal data or information which may be collected by us includes:
                            </p>
                            <p>full name and age; PAN; Masked Aadhaar; password of User’s account registered with us; postal address; E-mail address; mobile number; internet protocol (IP) addresses (through cookies); URL of website accessed prior to and post Users’ accessing our Website; credit/debit card number, credit/debit card expiration date and/or other payment instrument details; User’s feedback, queries, e-mails, letters, suggestions provided to us; third party information about concerned User’s activities or postings on the Website; Information about the mobile/tab device the App is installed on or mobile device identifier. any other information relevant for accessing and using our Website as maybe required by us. </p>
                            <p><span>Purpose of Collection</span>
                                <br />
                                We shall collect your Personal Information or Sensitive personal data or information only for specific, clear and lawful purposes. These include: to give the User a safe, efficient, smooth and personalised experience while using our Website; to improve our services and products; to respond to your queries or complaints generally related to our services, etc; to send promotional emails containing information about our existing and new products/services, newsletters, notify changes in this Policy or other policies etc; to analyse the traffic on our Website and conduct market research regarding users’ demographics, interests, and behaviour; to create new products and services; to provide customer support services; to detect, to enforce Terms of Conditions; other such lawful purposes as described at the time of collection of Personal Information or Sensitive personal data or information.
                            </p>
                            <p><span>Disclosure of information</span>
                                <br />
                                You expressly consent to and authorize us to share your Personal Information and Sensitive personal data or information with our trusted affiliates and any other third party, to the extent and for the lawful purposes as stated in this Policy. We shall not disclose any Personal Information or Sensitive personal data or information to any government institution or authority, except where such disclosure is (i) obligated by law, (ii) requested by a lawfully authorized government authority, (iii) pursuant to a judicial decree, (iv) for enforcing and protecting the legal rights of DMT or others’, (v) for seeking any relief, (vi) for defending any charge, (vii) for opposing any claim, (viii) for enforcing this Policy or our Terms of Use, (ix) for obtaining any legal advice. We also reserve our right to disclose, share or transfer your Personal Information or Sensitive personal data or information to a third party due to any change in ownership, merger, restructuring or sale of our business assets.
                            </p>
                            <p>Use of cookies and other technologies
                                <br />
                                To improve the effectiveness and usability of the Website for our Users, we use “cookies”, or similar technology to collect information and assign each visitor a unique random number called as a User Identification (User ID) in order to understand the User's individual interests. Our web servers automatically collect limited information about the User’s computer connection to the Internet, including User’s IP address, when the User visits the Website (User’s IP address is a number that lets computers connected to the internet know where to send data to the User such as the web pages viewed by the User).
                            </p>
                            <p>Users may encounter “cookies” or other similar devices on certain pages of the Website that are placed by third parties. We do not control the use of cookies by third parties and disclaim any obligation or liability associated thereto. </p>
                            <p><span>Links to other websites</span>
                                <br />
                                The Website provides links to other websites which may or may not collect information about the User. The User acknowledges that this Policy does not cover the collection and use of information by such sites and that we shall not be responsible for the privacy policies, practices or content of the linked websites.
                            </p>
                            <p><span>Retention of Information</span>
                                <br />
                                Your Personal Information and Sensitive personal data or information may be retained and used until: (i) the relevant purposes for the use of your information described in this Policy are no longer applicable; and (ii) we are no longer required by applicable law, regulations, contractual obligations or legitimate business purposes to retain your information; and (iii) the retention of your information is not required for the establishment, exercise or defines of any legal claim.
                            </p>
                            <p><span>Mailers</span>
                                <br />
                                DMT may send direct mailers to the User at the email address given by the User. The User has the option to opt-out of this direct mailer by way of links provided at the bottom of each mailer or any other means as DMT deems appropriate. DMT respects every User’s privacy and in the event that User chooses to not receive such mailers, DMT will take all steps to remove the User from the list. If a User wants to remove his/her/it’s contact information from all mailing lists and newsletters, the same can be done through “Profile” page of his/her/it’s account with DMT.
                            </p>
                            <p><span>Insurance</span>
                                <br />
                                Insurance is offered by DIALMYTRIP TECH PRIVATE LIMITED (CIN U93000DL2015PTC286253), Principal Place of Business: 516, Vishal Chamber, Sector – 18, Noida, GB Nagar - 201301, Uttar Pradesh. Registered Office: B-75, F/F, Plot No-1, New Ashok Nagar, Delhi - 110096, IRDAI Corporate Agent Registration Code - CA0756, License category- Corporate Agent (Life & General), valid till 01/07/2024. Product information is solely based on the information received from the insurers. For more details on risk factors, associated terms and conditions and exclusions, please read the sales brochure carefully of respective insurer before concluding a sale.
                            </p>
                            <p><span>How we secure the information</span>
                                <br />
                                The Website has put stringent security measures necessary to prevent misuse, unauthorised access, modification, disclosure or destruction of your Personal Information and Sensitive personal data or information in our control. All information collected by us is stored on servers secured behind a firewall; such servers are password-protected and access is strictly limited based on need-to-know basis.
                            </p>
                            <p>We may undertake periodic review of our security safeguards and this Policy to incorporate such future changes as may be appropriate to ensure that integrity of your Personal Information and Sensitive personal data or information is intact. In case of any security breach which is likely to cause harm to the User, we shall notify the User of such breach as soon as practicable and take necessary measures to remedy the breach or mitigate any immediate harm. </p>
                            <p>If you seek any clarifications regarding this Policy or wish to raise any issue or grievance with respect to illegal access, use or disclosure of Personal Information, please e-mail at hr@dialmytrip.com </p>
                            <p>We shall attempt to resolve any issues within a period of thirty (30) days from the date of receipt of the question, concern, grievance or report. </p>
                            <p>This Policy is published in compliance of: - Section 43A of the Information Technology Act, 2000 and Regulation 4 of the Information Technology Rules, 2011. </p>
                            <p>Thanking you for using DMT MEDIA & MARKETING PRIVATE LIMITED</p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Privacy
