import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../routes";
import MobileNavbar from "./MobileNavbar";
import ConsultForm from "../home/ConsultForm";

const Header = () => {
  const [cartshow, setCartshow] = useState(false);
  const [consult, setConsult] = useState(false);
  const FormShow = () => {
    setConsult(true);
  };
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      if (offset > 200) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <header

        className={`navigation bg-tertiary  main-header ${sticky ? "stickyHeader" : ""
          }`}
      >
        <nav className="navbar navbar-expand-xl navbar-light text-center ">
          <div className="container">
            <Link className="navbar-brand" to={WebRoutes.HOME}>
              <img
                loading="eager"
                decoding="async"
                
                className="img-fluid logo"
                src={require("../../static/img/dmtMedia.png")}
                alt="DMT MEDIA & MARKETING"
                title="DMT MEDIA & MARKETING"
              />
            </Link>
            <button
              className="navbar-toggler"
              onClick={() => setCartshow(true)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" to={WebRoutes.HOME}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={WebRoutes.ABOUT_US}>
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={WebRoutes.SERVICES}>
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={WebRoutes.PORTFOLIO}>
                    Portfolio
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={WebRoutes.CONTACT_US}>
                    Contact Us
                  </Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Pages
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li>
                      <Link className="dropdown-item" to={WebRoutes.HOME}>
                        Blog
                      </Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
              {/* <Link to={WebRoutes.HOME} className="btn btn-outline-primary">
                Log In
              </Link> */}
              <Link
                to={WebRoutes.HOME}
                onMouseEnter={FormShow}
                className="btn btn-T  ms-2 ms-lg-3 consult-btn"
              >
                Consult Now
              </Link>
            </div>
          </div>
        </nav>
      </header>
      {cartshow && (
        <MobileNavbar setCartshow={setCartshow} setConsult={setConsult} />
      )}

      {consult && <ConsultForm setConsult={setConsult} />}
      {/* <!-- End Header --> */}
    </>
  );
};

export default Header;
