const PortfolioArray=[

    {
        id:1,
        img:require("../../static/img/Cash Management.jpg"),
        link:"https://dialmytrip.com/Home/Dialmytrip/Home.aspx",
        title:"Dialmytrip",
        subtitle:{
            text1:"web development",
            text2:"Web Desig",
            text3:"Performance Marketing"
        }
    },
    {
        id:2,
        img:require("../../static/img/shardhajeet.jpg"),
        link:"https://sjf.dialmytrip.com",
        title:`Shardajeet Foundation`,
        subtitle:{
            text1:"web development",
            text2:"Web Desig",
            text3:"Performance Marketing"
        }
    },
    {
        id:3,
        img:require("../../static/img/jaypee.jpg"),
        link:"http://jalindia.com/",
        title:"Jaypee Group",
        subtitle:{
            text1:"web development",
            text2:"Web Desig",
            text3:"Performance Marketing"
        }
    },
    {
        id:4,
        img:require("../../static/img/suarbhiman.jpg"),
        link:"https://ihaats.com",
        title:"Swabhiman",
        subtitle:{
            text1:"web development",
            text2:"Web Desig",
            text3:"Performance Marketing"
        }
    },
    {
        id:5,
        img:require("../../static/img/fabrica.jpg"),
        link:"https://www.fabricafurnishings.com",
        title:"Fabrica Furnishing",
        subtitle:{
            text1:"web development",
            text2:"Web Desig",
            text3:"Performance Marketing"
        }
    },
    {
        id:6,
        img:require("../../static/img/gs.jpg"),
        link:"http://www.gathbandhansarees.com",
        title:"Gathbandhan Sarees",
        subtitle:{
            text1:"web development",
            text2:"Web Desig",
            text3:"Performance Marketing"
        }
    },
    {
        id:7,
        img:require("../../static/img/hbmk.jpg"),
        link:"https://hbmkretail.com/",
        title:"H B M K",
        subtitle:{
            text1:"web development",
            text2:"Web Desig",
            text3:"Performance Marketing"
        }
    },
    {
        id:8,
        img:require("../../static/img/3rdeye.jpg"),
        link:"https://3rdeyefoundation.org/",
        title:"3rd Eye Foundation",
        subtitle:{
            text1:"web development",
            text2:"Web Desig",
            text3:"Performance Marketing"
        }
    },
   
]

export default PortfolioArray;