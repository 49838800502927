import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import Header from "./common/header";
import Footer from "./common/footer";
import { IoLogoWhatsapp } from "react-icons/io";
const BaseComponent = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <NavLink
        to="https://api.whatsapp.com/send?phone=919355100668"
        target="_blank"
      >
        <div className="whatsapps-icon-fix">
          <IoLogoWhatsapp color="rgb(96,194,118)" size={50} />
        </div>
      </NavLink>
    </>
  );
};

export default BaseComponent;
