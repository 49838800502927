import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
const Slider = () => {

    return (
        <div className="row">
            <div className="col-10 mx-auto">
                <h3 className='py-3' data-aos="fade-right">Our Group of Companies</h3>
                <Swiper slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={false}
                    autoplay={
                        {
                            delay: 3000,
                            disableOnInteraction: false
                        }
                    }
                    navigation={false}

                    modules={
                        [Pagination, Navigation, Autoplay]
                    }
                    className="mySwiper">
                    <div className='desktop'>
                        <SwiperSlide>
                            <div className='row justify-content-around py-3'>
                                <div className='col-md-2  d-sm-block' key="1">
                                    <img loading="lazy" src={
                                        require("../../static/img/logo 2.png")
                                    }
                                        alt='logo2'

                                        title='logo2'
                                    />

                                </div>

                                <div className='col-md-2 d-none d-sm-block ' key="2">
                                    <img loading="lazy" src={
                                        require("../../static/img/logo3.png")
                                    }
                                        alt='logo2' title='logo3'/>
                                </div>
                                <div className='col-md-2 d-none d-sm-block ' key="3">
                                    <img loading="lazy" src={
                                        require("../../static/img/dmtlogo2.png")
                                    } width={300}
                                        alt='logo2' title='dmtlogo2'/>
                                </div>

                            </div>
                        </SwiperSlide>
                    </div>
                    <div className='desktop '>
                        <SwiperSlide>
                            <div className='row justify-content-around py-3'>
                                <div className='col-md-2 d-none  d-sm-block ' key="4">
                                    <img loading="lazy" src={
                                        require("../../static/img/logo 2.png")
                                    }
                                        alt='logo2' title='logo2' />
                                </div>

                                <div className='col-md-2   d-sm-block ' key="5">
                                    <img loading="lazy" src={
                                        require("../../static/img/logo3.png")
                                    }
                                        alt='logo2' title='logo2' />
                                </div>
                                <div className='col-md-2 d-none d-sm-block ' key="6">
                                    <img loading="lazy" src={
                                        require("../../static/img/dmtlogo2.png")
                                    } width={300}
                                        alt='logo2' title='logo2'/>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='row justify-content-around py-3'>
                                <div className='col-md-2 d-none d-sm-block' key="7">
                                    <img loading="lazy" src={
                                        require("../../static/img/logo 2.png")
                                    }
                                        alt='logo2' title='logo2'/>
                                </div>

                                <div className='col-md-2 d-none d-sm-block ' key="8">
                                    <img loading="lazy" src={
                                        require("../../static/img/logo3.png")
                                    }
                                        alt='logo2' title='logo2'/>
                                </div>
                                <div className='col-md-2  d-sm-block ' key="9">
                                    <img loading="lazy" src={
                                        require("../../static/img/dmtlogo2.png")
                                    } width={300}
                                        alt='logo2' title='logo2'/>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='row justify-content-around py-3'>
                                <div className='col-md-2 d-none d-sm-block' key="10">
                                    <img loading="lazy" src={
                                        require("../../static/img/logo 2.png")
                                    }
                                        alt='logo2' title='logo2'/>
                                </div>

                                <div className='col-md-2  d-sm-block ' key="11">
                                    <img loading="lazy" src={
                                        require("../../static/img/logo3.png")
                                    }
                                        alt='logo2'  title='logo2'/>
                                </div>
                                <div className='col-md-2 d-none d-sm-block ' key="12">
                                    <img loading="lazy" src={
                                        require("../../static/img/dmtlogo2.png")
                                    } width={300}
                                        alt='logo2'  title='logo2'/>
                                </div>

                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='row justify-content-around py-3'>
                                <div className='col-md-2 d-none d-sm-block' key="13">
                                    <img loading="lazy" src={
                                        require("../../static/img/logo 2.png")
                                    }
                                        alt='logo 2'  title='logo 2'/>
                                </div>

                                <div className='col-md-2 d-none d-sm-block ' key="14">
                                    <img loading="lazy" src={
                                        require("../../static/img/logo3.png")
                                    }
                                        alt='logo3' title='logo3'/>
                                </div>
                                <div className='col-md-2  d-sm-block ' key="15">
                                    <img loading="lazy" src={
                                        require("../../static/img/dmtlogo2.png")
                                    } width={300}
                                        alt='dmtlogo2' title='dmtlogo2' />
                                </div>

                            </div>
                        </SwiperSlide>
                    </div>
                </Swiper>
            </div>
        </div>
    )
}

export default Slider;
