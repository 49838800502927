import { Routes, Route } from "react-router-dom";
import { WebRoutes } from "./routes";
import BaseComponent from "./components";
import Home from "./components/home";
import About from "./components/about/about";
import Services from "./components/services/services";
import Portfolio from "./components/portfolio/portfolio";
import Contact from "./components/contact/contact";
import ScrollToTop from "./components/common/ScrollToTop";
import Privacy from "./components/common/Privacy";
import TermsCondition from "./components/common/TermsCondition";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const App = () => {

  useEffect(()=>{
    Aos.init({duration:2000});
  },[])
  return (
    <>
      <ScrollToTop>
        <Routes>
          <Route path={WebRoutes.HOME} element={<BaseComponent />}>
            <Route path={WebRoutes.HOME} element={<Home />} />
            <Route path={WebRoutes.ABOUT_US} element={<About />} />
            <Route path={WebRoutes.SERVICES} element={<Services />} />
            <Route path={WebRoutes.PORTFOLIO} element={<Portfolio />} />
            <Route path={WebRoutes.CONTACT_US} element={<Contact />} />
            <Route path={WebRoutes.PRIVACY} element={<Privacy />} />
            <Route path={WebRoutes.PRIVACY} element={<Privacy />} />
            <Route path={WebRoutes.TERMSCONDITION} element={<TermsCondition />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </>
  );
};

export default App;
