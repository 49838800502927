import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { AiTwotoneStar } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { WebRoutes } from "../../routes";

const MobileNavbar = ({ setCartshow, setConsult }) => {
  const [show, setShow] = useState(false);
  const crossHandle = () => {
    setShow(true);
    setCartshow(false);
    console.log(show);
  };
  const ConssultHandle = () => {
    setConsult(true);
    setCartshow(false);
  };
  return (
    <div className="cart-panel">
      <div className="opicity"></div>
      <div className="cart-content">
        <div className="header">
          <span className="heading">
            <img
              loading="prelaod"
              decoding="async"
              className="img-fluid logo"
              width={120}
              src={require("../../static/img/logo1.png")}
              alt="logo"
            />
          </span>
          <span className="close-btn" onClick={() => setCartshow(false)}>
            <MdClose />
            <span className="text">Close</span>
          </span>
        </div>
        <div className="menu-link">
          <ul>
            <li>
              <NavLink to={WebRoutes.HOME} onClick={crossHandle}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to={WebRoutes.ABOUT_US} onClick={crossHandle}>
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink to={WebRoutes.SERVICES} onClick={crossHandle}>
                Services
              </NavLink>
            </li>
            <li>
              <NavLink to={WebRoutes.PORTFOLIO} onClick={crossHandle}>
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink to={WebRoutes.CONTACT_US} onClick={crossHandle}>
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to=""
                onClick={ConssultHandle}
                className="btn btn-T  ms-2 ms-lg-3 consult-btn"
              >
                Consult Now
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="address-block">
          <ul>
            <li>
              <AiTwotoneStar color="yellow" size={20} />
              <NavLink>
                513, Vishal Chamber Sector-18, Noida Uttar Pradesh - 201301
              </NavLink>
            </li>
            <li>
              <AiTwotoneStar color="yellow" size={20} />
              <NavLink>+91 9818137167</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileNavbar;
