//***************Who we are and what we do array data******************

import { BsBullseye, BsTruck } from "react-icons/bs";
import { FaClipboardList, FaHandshake, FaMoneyCheckAlt } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";

const SomeData=[
    {
        id:0,
        icon:<BsTruck/>,
        tittle:"Professional Team",
        subtitle:"Consistent and Accountable Individuals"
    },
    {
        id:1,
        icon:<FaClipboardList/>,
        tittle:"Result Oriented Approach",
        subtitle:"Amazing Analytics & Reports"
    },
    {
        id:2,
        icon:<GiTeacher/>,
        tittle:"Strategic Management",
        subtitle:"Conversion Optimization"
    },
    {
        id:3,
        icon:<FaHandshake/>,
        tittle:"Strategic Alliance",
        subtitle:"Ideas that deliver ROI"
    },
    {
        id:4,
        icon:<BsBullseye/>,
        tittle:"Our Mission",
        subtitle:"Our mission is to help businesses to grow more digitally stay ahead of the curve and succeed in an ever-changing digital world."
    },
    {
        id:5,
        icon:<FaMoneyCheckAlt/>,
        tittle:"Affordable Pricing",
        subtitle:"Effective & Affordable"
    },

]


export default SomeData;