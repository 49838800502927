
//core values
const CoreValue = [
  {
    id: 1,
    img: require("../../static/img/Untitled-1 (1).png"),
    text: "Innovation",
  },
  {
    id: 2,
    img: require("../../static/img/Untitled-1.png"),
    text: "Leadership",
  },
  {
    id: 3,
    img: require("../../static/img/Untitled-3.png"),
    text: "Integrity",
  },
  {
    id: 4,
    img: require("../../static/img/Untitled-4.png"),
    text: "Trust",
  },
  {
    id: 5,
    img: require("../../static/img/Untitled-5.png"),
    text: "Team Work",
  },
  {
    id: 6,
    img: require("../../static/img/Untitled-8.png"),
    text: "Support",
  },
];

export const CompanyDetails = [
  {
    id: 1,
    heading: "Dialmytrip",
    image: require("../../static/img/logo 2.png"),
    para: "Fintech company with diversified business interests in areas like Financial Inclusion, Travel, Technology, Telecom, Insurance etc. Dialmytrip is actively working to provide an advanced and accessible ecosystem for a wide Representation all over India.",
  },
  {
    id: 2,
    heading: "Shardajeet Foundation",
    image: require("../../static/img/logo3-removebg-preview.png"),
    para: "SJF is a non-profitable organization registered under Section 8 of the Company’s Act 2013. We work in the areas like Jharkhand and also have it as our main center. We have a team of professionals and a management team to manage all the things on the ground and on social media platforms.",
  },
  {
    id: 3,
    heading: "DMT Accountancy",
    image: require("../../static/img/dmtlogo2.png"),
    para: "Our mission is to simplify finances and save time & money for Indian businesses. We help businesses with their day-to-day taxation & finance-related efforts. To provide exceptional financial and professional services while maintaining the highest levels of integrity and professionalism & meet the current and future needs of our clients and help them to cultivate long-term success.",
  },
];

// export const BoardOfAdvisor = [
//   {
//     id: 1,
//     image: require("../../static/img/Gautam.png"),
//     name: "Gautam Chatterjee",
//     desi: "Vice President",
//     group: "DMT GROUP",
//     logo: (
//       <BsEnvelopeAtFill
//         size={20}
//         color=""
//         style={{
//           color: "blue",
//           transform: "translateY(-9px)",
//         }}
//       />
//     ),
//     message:
//       "A senior level executive who have been consistently contributing towards various organisational stints building a highly comprehensive professional experience in Operations & Process Management",
//     icon: {
//       mail: <BsEnvelopeAtFill />,
//       phone: <MdCall />,
//     },
//   },
//   {
//     id: 2,
//     image: require("../../static/img/Deepak.png"),
//     name: "Deepak Kumar",
//     desi: "Head Account & Finance",
//     group: "DMT GROUP",
//     logo: (
//       <BsEnvelopeAtFill
//         size={20}
//         color=""
//         style={{
//           color: "blue",
//           transform: "translateY(-9px)",
//         }}
//       />
//     ),
//     sent: "https://www.linkedin.com/in/deepak-kumar-56387095/",
//     message:
//       "Mr. Deepak Kumar is a Highly motivated and Goal-oriented finance & Accounts professional. He has expertise in financial strategy & analysis with numerous years of rich experience.",
//     icon: {
//       linkedin: <BsLinkedin size={20} />,
//       mail: <BsEnvelopeAtFill size={20} />,
//       phone: <MdCall size={20} />,
//     },
//   },
//   {
//     id: 3,
//     image: require("../../static/img/Syed.png"),
//     name: "Syed Aslam",
//     desi: "Operation Head",
//     group: "DMT GROUP",
//     logo: (
//       <BsEnvelopeAtFill
//         size={20}
//         color=""
//         style={{
//           color: "blue",
//           transform: "translateY(-9px)",
//         }}
//       />
//     ),
//     sent: "https://www.linkedin.com/in/syed-aslam-1019aa23a/",
//     message:
//       "Syed Aslam is the Chief Operating Officer of DIALMYTRIP. He has several years of experience and plays a vital role in the company by applying multiple duties altogether by operating the strategic direction & team management.",
//     icon: {
//       linkedin: <BsLinkedin />,
//       mail: <BsEnvelopeAtFill />,
//       phone: <MdCall />,
//     },
//   },
// ];
export default CoreValue;
