import { Link } from "react-router-dom";
import { WebRoutes } from "../../routes";
import ReCAPTCHA from "react-google-recaptcha";
import PortfolioArray from "./portfolioArray";

import { useState } from "react";
import { Helmet } from "react-helmet-async";

// import axios from "axios";

const Portfolio = () => {
  const [consultData, setConsultData] = useState({
    user_name: "",
    user_mobile: "",
    user_email: "",
    user_service: "",
  });

  const InputHandler = (e) => {
    setConsultData({
      ...consultData,
      [e.target.name]: e.target.value,
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (
      consultData.user_name === "" ||
      consultData.user_email === "" ||
      consultData.user_mobile === "" ||
      consultData.user_service === ""
    ) {
      alert("Please fill all the fields");
      return false;
    }

    // console.log(consultData);
    // axios.post("http://localhost:3002/api", consultData).then(() => {
    //     // console.log(consultData);
    // }).catch((err) => {
    //     console.log(err);
    // })
    console.log(consultData);
    setConsultData({
      user_name: "",
      user_mobile: "",
      user_email: "",
      user_service: "",
    });

    alert("message send successfully");
  };
  return (
    <>
      <Helmet>
        <title>DMT Media & Marketing Portfolio</title>
        <meta name="description" content="Explore the DMT Media portfolio showcasing our successful digital marketing campaigns." />
        <meta name="keywords" content="Digital Marketing Portfolio, Social Media & Marketing" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <section className="page-header  portfolio-page">
        <div className="container">
          <div className="row">
            <div className="col-8 mx-auto text-center">
              <h1 className="mb-3 text-capitalize">Portfolio</h1>
              <ul
                className="breadcrumbs"
                style={{ transform: "translateX(-16px)" }}
              >
                <li className="list-inline-item">
                  <Link to={WebRoutes.HOME}>Home</Link>
                </li>
                <li className="list-inline-item">
                  <Link to={WebRoutes.PORTFOLIO}>Portfolio</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="section ">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="our-work-heading-portfolio" data-aos="fade-right">
                <span className="" style={{ color: "#1074BE" }}>
                  OUR
                </span>
                <span className="" style={{ color: "#1074BE" }}>
                  {" "}
                  WORK
                </span>
              </h2>
              <p className="gap-5 d-flex justify-content-center">
                <span
                  data-aos="fade-right"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                >
                  <i
                    className="fa-solid fa-handshake fa-xl"
                    style={{ color: "blue" }}
                  ></i>
                </span>
                <span
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                >
                  <i
                    className="fa-solid fa-hand-peace fa-xl"
                    style={{ color: "blue" }}
                  ></i>
                </span>
                <span
                  data-aos="fade-left"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                >
                  <i
                    className="fa-solid fa-thumbs-up fa-xl"
                    style={{ color: "blue" }}
                  ></i>
                </span>
              </p>
              <p data-aos="fade-up">
                {" "}
                DMT MEDIA & MARKETING PRIVATE LIMITED helps businesses to grow
                more digitally, From launching a new business to digitizing,
                streamlining or scaling an existing setup we believe our
                customized solutions will help you boost your Brand and increase
                Revenue. Our team of experts is skilled in developing unique and
                creative marketing solutions that capture attention and engage
                audiences. At DMT MEDIA & MARKETING PRIVATE LIMITED, we work
                with our clients to develop a customized marketing strategy that
                balances different approaches to achieve your marketing
                objectives.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-tertiary">
        <div className="container-fluid">
          <div className="row gy-2 justify-content-around">
            {PortfolioArray.map((item) => {
              return (
                <div
                  className="col-md-6 col-lg-3 col-6 port-main"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  key={item.id}
                >
                  <div className="p-2 border border-dark text-center  position-relative">
                    <img
                      src={item.img}
                      alt={item.title}
                      title={item.title}
                      loading="lazy"
                      style={{ height: "auto", width: "auto" }}
                    />
                    <div className="portfolio-card" key={item.id}>
                      <div className="content-portfolio">
                        <h5 className="text-white mb-3 text-wrap">
                          {item.title}
                        </h5>

                        <button className="btn btn-primary text-dark bg-white">
                          <a
                            rel="noreferrer"
                            href={item.link}
                            target="_blank"
                            className="text-black"
                          >
                            Visit
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}{" "}
          </div>
        </div>
      </section>
      <section className="section lets-connected-page">
        <div className="container">
          <div className="col-12" data-aos="fade-left">
            <h3 className="text-center border-bottom  lets-connected-heading mb-3 ">
              Let's Connect
            </h3>
          </div>
          <div
            className="col-md-4 col-11 rounded border mx-auto backdropBg"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <form className="">
              <div className="mb-2">
                <label
                  htmlFor="recipient-name"
                  className="col-form-label text-black"
                >
                  Name:*
                </label>
                <div>
                  <input
                    type="text"
                    id="recipient-name"
                    className="w-100 p-2 rounded shadow-md border-1"
                    name="user_name"
                    placeholder="Enter Name"
                    value={consultData.user_name}
                    onChange={InputHandler}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="mobile" className="col-form-lable text-black">
                  Mobile:*
                </label>
                <div>
                  <input
                    type="text"
                    id="mobile"
                    className="w-100 p-2 rounded shadow-md border-1 "
                    maxLength={10}
                    pattern="[0-9]*"
                    name="user_mobile"
                    placeholder="Enter Number"
                    value={consultData.user_mobile}
                    onChange={InputHandler}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="email" className="col-form-lable text-black">
                  Email:*
                </label>
                <div>
                  <input
                    type="email"
                    id="email"
                    className="w-100 p-2 rounded shadow-md border-1 "
                    name="user_email"
                    value={consultData.user_email}
                    placeholder="Enter Email"
                    onChange={InputHandler}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="mb-2">
                <label
                  htmlFor="message-text"
                  className="col-form-label text-black"
                >
                  Message:*
                </label>
                <div>
                  <select
                    className="w-100 p-3 shadow-md border-1 rounded "
                    value={consultData.user_service}
                    name="user_service"
                    onChange={InputHandler}
                    required
                  >
                    <option>Select Services</option>
                    <option>Social Media Marketing</option>
                    <option>Digital Marketing</option>
                    <option>SEO</option>
                    <option>Creative Work</option>
                  </select>
                </div>
              </div>
              <div className=" my-2  rounded text-center ">
                <ReCAPTCHA
                  className="recaptcha"
                  sitekey="6Lfxz54nAAAAAAtOoMMN2AYP4bJNEHjekx0vb6Ro
                "
                />
              </div>
              <div className="text-center mb-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={submitHandler}
                >
                  Send message
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
