import React from "react";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../routes";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      {/* <!-- ======= Footer ======= --> */}
      <footer className="bg-tertiary py-5">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4 col-md-12 newsletter-form">
              <div
                style={{
                  backgroundColor: "#F4F4F4",
                  padding: "30px",
                }}
              >
                <Link to={WebRoutes.HOME} className="d-block mb-4">
                  <img
                    loading="eager"
                    decoding="async"
                    className="img-fluid logo"
                    src={require("../../static/img/dmtMedia.png")}
                    alt="DMT MEDIA & MARKETING"
                    title="DMT MEDIA & MARKETING"
                  />
                </Link>
                <p className="mb-0">
                  We help companies to grow their businesses digitally. The
                  unique combination of strategic thinking marketing expertise
                  operational know-how digital transformation capabilities and
                  advanced analytics.
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 mb-4">
              <div className="footer-widget">
                <h5 className="mb-4 text-primary font-secondary">
                  Quick Links
                </h5>
                <ul className="list-unstyled ">
                  <li className="mb-2 ">
                    <Link to={WebRoutes.ABOUT_US}>About Us</Link>
                  </li>
                  <li className="mb-2">
                    <Link to="https://apipartner.dialmytrip.com/">Services</Link>
                  </li>
                  <li className="mb-2">
                    <Link to={WebRoutes.PORTFOLIO}>Portfolio</Link>
                  </li>
                  <li className="mb-2">
                    <Link to={WebRoutes.CONTACT_US}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-6 mb-4">
              <div className="footer-widget">
                <h5 className="mb-4 text-primary font-secondary">Service</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <Link to={WebRoutes.SERVICES}>
                      Search Engine Optimization
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to={WebRoutes.SERVICES}>Social Media Marketing</Link>
                  </li>

                  <li className="mb-2">
                    <Link to={WebRoutes.SERVICES}>Digital Marketing</Link>
                  </li>
                  <li className="mb-2">
                    <Link to={WebRoutes.SERVICES}>Creative Work</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4  mb-4">
              <div className="footer-widget">
                <h5 className="mb-4 text-primary font-secondary">Help</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <Link to={WebRoutes.CONTACT_US}>Contact Us</Link>
                  </li>
                  <li className="mb-2 ">
                    <Link to={WebRoutes.HOME}>Follow Us</Link>
                  </li>
                </ul>

                <ul className="list-unstyled list-inline mb-0 social-icons">
                  <li className="list-inline-item me-3">
                    <Link
                      className="text-black facebook"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="dmt.mediamarketing"
                      to="https://www.facebook.com/dmt.mediamarketing/"
                    >
                      <i className="fab fa-facebook-f"></i>
                      <span class="visually-hidden">Go to Example</span>
                    </Link>
                  </li>
                  <li className="list-inline-item me-3">
                    <Link
                      className="text-black twitter"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="DMTMediaIndia"
                      to="https://x.com/DMTMediaIndia"
                    >
                      <i className="fab fa-twitter"></i>
                      <span class="visually-hidden">Go to Example</span>
                    </Link>
                  </li>
                  <li className="list-inline-item me-3">
                    <Link
                      className="text-black instagram"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="dmt_mediamarketing"
                      loading="lazy"
                      to="https://www.instagram.com/dmt_mediamarketing/"
                    >
                      <i className="fab fa-instagram"></i>
                      <span class="visually-hidden">Go to Example</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="row align-items-center mt-5 text-center text-md-start">
            <div className="col-lg-7 col-md-6 mt-4 mt-lg-0">
              {`© Copyright ${year} DMT Media & Marketing Pvt Ltd. All Rights Reserved Copyright`}
            </div>
            <div className="col-lg-5 col-md-6 text-md-end mt-4 mt-md-0">
              <ul className="list-unstyled list-inline mb-0">
                <li className="list-inline-item me-4">
                  <Link className="text-black" to={WebRoutes.PRIVACY}>
                    Privacy Policy
                  </Link>
                </li>
                <li className="list-inline-item me-4">
                  <Link className="text-black" to={WebRoutes.TERMSCONDITION}>
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}{" "}
    </>
  );
};

export default Footer;
